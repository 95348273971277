
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Patients List</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-lg-12 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="toggleFilters()">
                <button class="dropdown-item" type="button">
                  Toggle Filters
                </button>
              </li>
              <li @click.prevent="exportData()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <button @click.prevent="toggleFilters()" type="button" class="btn btn-primary" >
            <i class="ri ri-filter-2-line align-bottom"></i>  Filters </button>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select @change="fetchItems()" v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="">
        <common-portlet headertitle="Filters"
         :visible="enableFilters" :open="true">
          <div class="loading-viewport">
            <div class="card-body p-lg-3">
              <hospital-patient-filter-form 
              @startSearch="setFilters($event)"
              @resetFilters="initializeAll()" />
            </div>
          </div>
        </common-portlet>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-slot:cell(patient)="data">
                  <div style="min-width:12rem;" v-if="data.item.patient" class="">
                    <h6><router-link :to="`/patients/find?number=${data.item.patient.friendly_id}`">{{ data.item.patient.friendly_id }} </router-link> </h6>
                    <p class="mb-0"><strong>Name:</strong> {{ data.item.patient.first_name }} {{ data.item.patient.last_name }} </p>
                    <p class="mb-0"><strong>Phone:</strong> {{ data.item.patient.mobile }} </p>
                  </div>
                </template>
                <template v-slot:cell(doctor)="data">
                  <div style="min-width:12rem;" v-if="data.item.doctor" class="">
                    <div class="mb-1">
                      <img :src="absoluteUrl(data.item.doctor.image)" class="rounded-circle" style="width:30px;height:30px;" />
                    </div>
                    <p class="mb-0"><strong>Name:</strong> {{ data.item.doctor.first_name }} {{ data.item.doctor.last_name }} </p>
                    <p class="mb-0"><strong>Phone:</strong> {{ data.item.doctor.mobile }} </p>
                  </div>
                </template>
                <template v-slot:cell(tag)="data">
                  <div style="min-width:4rem" class="">
                    {{ data.item.tag }}
                  </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div style="min-width:6rem" class="">
                    <span v-html="statusBadge(data.item.status)"></span>
                  </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <div style="min-width:6rem;max-width:6rem;" class="">
                    {{ $filters.date(data.item.created_at, 1) }}
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <div class="dropdown dropstart no-arrow">
                    <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                      role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click.prevent="reAssignDoctor(data.item)" class="dropdown-item" type="button">
                          Re-Assign to Doctor
                        </button>
                      </li>
                    </ul>
                  </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
    title="Re-Assign patient to a Doctor" body-class="loading-viewport"
    title-class="fs-6" @hide="initializeAll()">
      <hospital-patient-form v-if="popupModalShow" 
      :editItem="editItem" :editMode="true" @closeMe="initializeAll()"/>
  </b-modal>
  </div>
</template>

<script>

import _ from 'lodash';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';
import IsLoading from '@/components/IsLoading.vue';
import HospitalPatientFilterForm from '@/components/forms/HospitalPatientFilterForm.vue';
import HospitalPatientForm from '@/components/forms/HospitalPatientForm.vue';

export default {
  name: "hospital-patients",
  components:{
    IsLoading,
    CommonPortlet,
    HospitalPatientFilterForm,
    HospitalPatientForm
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      editItem: null,
      enableFilters: false,
      queryFilters: null,
      searchQuery: "",
      defaultStatus: "on_queue",
      statusOptions:[
        { value: null , label: "All" },
        { value: "on_queue", label: "On queue" },
        { value: "in_consultation", label: "In consultation" },
        { value: "checked_out", label: "Checked out" },   
        { value: "cancelled", label: "Cancelled" },     
        { value: "admitted", label: "Admitted" },
        { value: "discharged", label: "Discharged" },   
        { value: "scheduled", label: "Scheduled" },     
        { value: "under_treatment", label: "Under treatment" },
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "patient",
          label: "Patient",
        },
        {
          key: "doctor",
          label: "Doctor",
        },
        {
          key: "tag",
          label: "Tag",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_at",
          label: "Date",
        },
        "action"
      ],
    }
  },
  computed: {
    pageData(){
      return this.$store.state.hospitalPatient.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$store.dispatch("hospitalPatient/fetchItems", payload)
      .then(response => this.isLoading = false)
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    reAssignDoctor(item){
      this.editItem = item;
      this.popupModalShow = true
    },
    exportData() {
      const payload = {
        url: '/hospital-patients/export-excel',
        filename: 'hospital-patients_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate record');
          }
      })
    },
    initializeAll(){
      this.queryFilters = null;
      this.popupModalShow = false
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

