
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3"> 
        <div class="col-lg-6">
            <label> Select Doctor </label>
            <multiselect 
                mode="single"
                searchable
                valueProp="id"
                trackBy="name" label="name"
                :options="doctorOptions" 
                v-model="data.doctor_id" placeholder="--select doctor --">
                <template v-slot:option="{ option }">
                    <div class="d-flex align-items-center">
                        <img :src="absoluteUrl(option.image)" style="width:40px;height:40px;" class="me-2 rounded-circle" />
                        <strong>{{ option.name }}</strong>
                    </div>
                </template>
            </multiselect>
        </div>  
        <div class="col-lg-6">
            <label class="form-label">Assign Date</label>
            <flat-pickr
                v-model="data.created_between"
                :config="rangeConfig"
                placeholder="Select date"
                class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>

import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue'
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        IsLoading,
        Multiselect,
        flatPickr
    },
    data() {
        return {
            isLoading: false,
            data: {
                created_between: "",
                doctor_id: null,
            },
            rangeConfig: {
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 2,
                altFormat: "F j, Y",
                // minDate: "today",
                maxDate: new Date().fp_incr(1)
            },
            mirrorData: null,
        }
    },
    computed:{
        doctorOptions(){
            return this.$store.state.doctors
        }
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch('fetchDoctors').then((response) => {
                this.isLoading = false;
            });
        },
    },
    created(){
        this.getOptionData()
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
